import React, {useEffect} from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import $ from 'jquery'
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"

const Sitemap = (props) => {
useEffect(() => {
$('.site-map-page').insertAfter('.content-section-static h1');
});
const data = useStaticQuery(graphql`
query  {  
glstrapi {
allMenus(sort:"Sorting:asc") {
id
Label
Primary_URL
Secondary_URL
Show_In_Burger
New_Tab_Open
Hide_Menu_In_Sub_Nav
Main_Parent {
id
Label
Primary_URL
Secondary_URL
Disable_Parent_URL            
New_Tab_Open
}
Sub_Parent {
id
Label
Primary_URL
Secondary_URL            
}
}
}
}
`)
var menu_data = data.glstrapi.allMenus;
return (
<div className="site-map-page">
<ul className="mainSubmenu">
{menu_data.map((menuitem) => {
return <>
{menuitem.Show_In_Burger === true &&
<>

<li className="main-menu">
{menuitem.Secondary_URL ?
<Link to={menuitem.Secondary_URL}>{menuitem.Label}</Link>
:
<Link to={`/${menuitem.Primary_URL}`}>{menuitem.Label}</Link>
}
<ul className="submenu">
{menu_data.map((submenu) => {
return <>
{submenu.Main_Parent && submenu.Main_Parent.Label == menuitem.Label && submenu.Sub_Parent == null &&
<>
    <li>
        {(submenu.Secondary_URL && submenu.New_Tab_Open == false) ?
        <Link to={`${submenu.Secondary_URL}`}>{submenu.Label}</Link>
        :
        (submenu.Secondary_URL && submenu.New_Tab_Open == true)?            
        <a target="_blank" href={submenu.Secondary_URL}>{submenu.Label}</a>
        :
        (submenu.Main_Parent.Disable_Parent_URL == true)?
        <Link to={`/${submenu.Primary_URL}`}>{submenu.Label}</Link>
        :
        <Link to={`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}`}>{submenu.Label}</Link>
        }
        <ul className="submenu2">
            {menu_data.map((submenu2) => {
                return <>
                    {submenu2.Main_Parent != null && submenu2.Sub_Parent != null && submenu2.Sub_Parent.Label === submenu.Label &&
                        <li>
                            {submenu2.Secondary_URL ?
                            <Link to={`${submenu2.Secondary_URL}`}>{submenu2.Label}</Link>
                            :     
                            (submenu2.Main_Parent.Disable_Parent_URL == true)?
                            <Link to={`/${submenu2.Sub_Parent.Primary_URL}/${submenu2.Primary_URL}`}>{submenu2.Label}</Link>
                            :
                            <Link to={`/${submenu2.Main_Parent.Primary_URL}/${submenu2.Sub_Parent.Primary_URL}/${submenu2.Primary_URL}`}>{submenu2.Label}</Link>
                            }
                        </li>
                    }
                </>
            })}
        </ul>
    </li>
</>
}
</>
})}
{menuitem.Label === "Search" &&
<>
<li><Link to={`/property/for-sale`}>Property for Sale</Link></li>
<li><Link to={`/property/to-rent`}>Property to Rent</Link></li>
</>
}
</ul>
</li>
</>
}
</>
})}
</ul>


</div>

)
}
export default Sitemap;
