import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';

const AuctionModule = () => {
    return (
        <React.Fragment>
            
            <div className="search-list-block blue-full-width">
                <div className="appraisal-wrap">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="appraisal-bar">
                                <div className="left-block">
                                    <h2>Do you want us to Market your property?</h2>
                                    <p>
                                    We offer a FREE, no obligation auction appraisal.
                                    </p>
                                </div>
                                <div className="right-block">
                                    <Link to="/property-valuation/" className="btn">
                                        Book your market appraisal
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default AuctionModule;
