import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './OurSales.scss'
import GetCTA from "../../getcta";
// markup

const Successful = (props) => {
    return (
        <React.Fragment>
            <section className="successful autions-sales">
                
                    <Container>
                        <Row>
                            <Col>
                                <h2>{props.heading}</h2>
                                <div className="box-wrapper d-flex justify-content-between">
                                        {props.successlist && props.successlist.map((item) => {
                                            return<>
                                            <div className="sale-box">                                        
                                            <a href="javascript:;" className="icons">
                                            <i className="icon-house">
                                            </i>
                                            </a>
                                            <h2>{item.Sale_Item_Title}</h2>
                                            <p>{item.Sale_Item_Caption}</p>
                                            </div>    
                                            </>                                
                                        })}                                       
                                </div>
                                <div className="btn-wrapper d-md-flex justify-content-center">                                   
                                    <GetCTA class="btn btn-primary" Label={props.cta1label} link={props.cta1link && props.cta1link.Primary_URL} />
                                    <a href={props.cta2link} target="_blank" className="btn btn-primary auction-btn">
                                        {props.cta2label}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        </React.Fragment >
    )
}
export default Successful

