import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import { AltSiteName,OfficeBaseURL } from "../../utils";
import ScrollAnimation from 'react-animate-on-scroll';
import './SalesLocation.scss'
import OfficeLandingMap from "../../office-landing-map";
import ContactModule from "../../CarrierLanding/Connect/ContactModule";
// markup
const OfficeListing = (props) => {
    return (
        <React.Fragment>
            <div className="office-listing-wrap">
            <div className="sales-location office-listing">
                    <Container>
                        <Row>
                                {props.GQLOffices && props.GQLOffices.map((item)=>{
                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.Tile_Image_Transforms) {
                                    processedImages = item.imagetransforms.Tile_Image_Transforms;
                                    }
                                    return<>
                                    <Col md={6} lg={3} className="office-item">
                                    <div className="location-info">
                                    <div className="location-img img-zoom">
                                    <Link to={`${OfficeBaseURL}${item.URL}/`}>
                                    <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="our-offices.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Name+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                                    </Link>
                                    </div>
                                    <div className="location-details">
                                        <h2><Link to={`${OfficeBaseURL}${item.URL}/`}>{item.Name}</Link></h2>
                                        <address>{item.Office_Address}</address>
                                        {item.Sales_Phone_Number?
                                        <div className="sales-contact">
                                            Sales
                                            <a href={`tel:${item.Sales_Phone_Number}`} className="tel">
                                                {item.Sales_Phone_Number}
                                            </a>
                                        </div>
                                        :""}
                                        {item.Lettings_Phone_Number?
                                        <div className="sales-contact">
                                            Lettings
                                            <a href={`tel:${item.Lettings_Phone_Number}`} className="tel">
                                            {item.Lettings_Phone_Number}   
                                            </a>
                                        </div>
                                        :""}
                                        {(item?.Sales_Phone_Number === "" || item?.Lettings_Phone_Number === "") &&
                                            <div className="no sales-contact"><span></span></div>
                                        }
                                    </div>
                                </div>
                                </Col>
                                    </>
                                })}
                            
                        </Row>
                    </Container>
                
            </div>
        <OfficeLandingMap GQLOffices={props.GQLOffices}/>
        <ContactModule style={`d-none`}/>
        </div>
        </React.Fragment >
    )
}
export default OfficeListing