import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./Terms.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll"
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName, ContactFormURL } from "../../utils"
// Header component

const SubAccordionList = (props) => {
    
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-minus" : "icon-plus"
                    }
                ></i>
            </button>
        )
    }
    var subaccordion  = props.subaccordion

    return (
      
                                    <Accordion flush>
                                    
                                        {subaccordion &&
                                        <div className="sub-accordion">
                                        <>
                                        {subaccordion && subaccordion.map((subitem,index) => {
                                        return<>
                                        <Card className="balgores-heading">
                                            <div className="accordion-header card-header">
                                                {/* <ContextAwareToggle eventKey={subitem.id} className="accordion-header">
                                                    <h4>{subitem.Sub_Accordion_Title}</h4></ContextAwareToggle> */}
                                            </div>
                                            {/* <Accordion.Collapse eventKey={subitem.id}> */}
                                                <div className="accordion-card accordion-card-first card-body">
                                                    {subitem.Sub_Accordion_Team_Details && subitem.Sub_Accordion_Team_Details.map((subitemteam,index) => {
                                                    return<>
                                                    {/* <h3>{subitemteam.Team_Office_Name}</h3> */}
                                                    <div className="staff-wrapper d-md-flex">
                                                    {subitemteam.Select_Office_Members.map((subteam,index) => {
                                                        return<>
                                                        <div className="staff-details d-flex">
                                                            <div className="staff-img">
                                                                <img src={getSrc(subteam.Staff_Image.url_sharp) } alt={subteam.Staff_Image.alternativeText?subteam.Staff_Image.alternativeText:subteam.Name+AltSiteName} />
                                                            </div>
                                                            <div className="staff-info d-flex flex-column">
                                                                <h3>{subteam.Name}</h3>
                                                                <span className="profile">{subteam.Designation}</span>
                                                                <Link to={`${ContactFormURL}?tid=${subteam.id}`} className="mail">Email</Link>
                                                            </div>
                                                        </div>
                                                        </>
                                                    })}
                                                    </div>
                                                    </>
                                                    })}
                                                </div>

                                            {/* </Accordion.Collapse> */}
                                        </Card>
                                        </>
                                        })}
                                        </>
                                        </div>
                                        }
                                    </Accordion>
                              
    );
};
export default SubAccordionList;

