import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getSrc } from "gatsby-plugin-image"
import "./iconBlock.scss";

const IconBlock =(props)=> {
   return (
    <section className="icon-block-wrapper">
        <Container>
            <Row>
                {props.icon_list && props.icon_list.map((item, index) => {
                    let iconUrl = item.Icon.url_sharp ? getSrc(item.Icon.url_sharp) : item?.Icon?.url;
                    return (
                            <Col md={6} lg={4} key={index} className="text-center icon-grid-item">                               
                                {iconUrl &&                                
                                    <img src={iconUrl} alt={item.Icon.alternativeText?item.Icon.alternativeText:item.Icon_Name }  />
                                }
                                {item?.Icon_Name && <h4>{item.Icon_Name}</h4>}
                            </Col>
                    )
                })
                }
            </Row>
        </Container>
    </section>
   );
};
export default IconBlock;