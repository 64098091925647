import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import { AltSiteName, OfficeBaseURL } from "../../utils";
import ScrollAnimation from 'react-animate-on-scroll';
import Select from "react-dropdown-select"
import './ReviewList.scss'
import OfficeLandingMap from "../../office-landing-map";
import ContactModule from "../../CarrierLanding/Connect/ContactModule";
// markup
const OfficeListing = (props) => {
    const [reviewist, setReviewList] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        let optionval = []
        let revList = []
        {
            props.GQLOffices.map(val => {
                if (val.Elfsight_Code) {
                    optionval.push({ value: val.URL, label: val.Name })
                    revList.push(val)
                }

            })
        }
        setReviewList(revList)
        setOptions(optionval)
    }, [props.GQLOffices])
    const reviewDetail = (e) => {
        if (e[0].value) {
            navigate(`/about/customer-reviews/${e[0].value}/`)
        }
    }
    return (
        <React.Fragment>
            <div className="review-listing-wrap">

                <div className="review-listing office-listing">
                    <Container>

                        <Row>
                            <Col lg={3} className="elfsight-selectbox">
                                <Select placeholder="Choose your local branch" searchable={false} options={options} onChange={(reviewDetail)} />
                            </Col>
                        </Row>
                        <Row>
                            {reviewist.map((item) => {
                                let processedImages = JSON.stringify({});
                                if (item?.imagetransforms?.Tile_Image_Transforms) {
                                    processedImages = item.imagetransforms.Tile_Image_Transforms;
                                }
                                return <>
                                    <Col md={6} lg={3} className="office-item">
                                        <div className="location-info">
                                            <div className="location-img img-zoom">
                                                <Link to={`/about/customer-reviews/${item.URL}/`}>
                                                    <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="our-offices.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText ? item.Tile_Image.alternativeText : item.Name + AltSiteName }} imagetransformresult={processedImages} id={item.id} />
                                                </Link>
                                            </div>
                                            <div className="location-details">
                                                <h2><Link to={`/about/customer-reviews/${item.URL}/`}>{item.Name}</Link></h2>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            })}

                        </Row>
                    </Container>

                </div>
            </div>
        </React.Fragment >
    )
}
export default OfficeListing