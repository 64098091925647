import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { AltSiteName, TeamBaseURL, ContactFormURL } from "../../utils";
import "./Team.scss"
// import { AllTeamsList, AllTeams } from "../../../queries/common_use_query";
import TeamListFilter from './TeamListingFilter'
const postsPerPage = 12;
let arrayForHoldingPosts = [];

const Team = (props) => {
  //const {loading:loading, error:error, data:data} = AllTeams(props.Teamfilter);
  var teams = props.teams
  var filter = props.Teamfilter ? props.Teamfilter : 'ALL'
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(12);
  useEffect(() => {
    arrayForHoldingPosts = [];
  }, []);
  // useEffect(() => {
  //   arrayForHoldingPosts = [];
  // }, [props.Teamfilter]);
  const loopWithSlice = (start, end) => {
    const slicedPosts = teams.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };
  useEffect(() => {
    teams && loopWithSlice(0, postsPerPage);
  }, [teams]);
  const ImageRenderList = ({ image_url, item, processedImages }) => {
    return (
      <ImageTransform imagesources={item.Staff_Image && item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.commontile" attr={{ alt: item.Staff_Image && item.Staff_Image.alternativeText ? item.Staff_Image.alternativeText : item.Name + ' - ' + item.Designation + AltSiteName }} imagetransformresult={processedImages} id={item.id} />

    )
  }
  return (
    <React.Fragment>
      <section className="team-row team-listing-page">

        <Container>
          {filter == "ALL" ?
            <div className="team-slider row">
              {postsToShow && postsToShow.length > 0 && postsToShow.map((item, index) => {
                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.Staff_Image_Transforms) {
                  processedImages = item.imagetransforms.Staff_Image_Transforms;
                }
                return <>
                  {/* {item.Staff_Image && */}
                  <div className="team-item col-md-6 col-lg-3">
                    {/* <Link to={`${TeamBaseURL}/${item.URL}`} > */}
                      {/* <a href="javascript:;" className="img-zoom"> */}
                        {/* <ImageRenderList processedImages={processedImages} image_url={item.Staff_Image && item.Staff_Image.url} item={item} /> */}
                        <div className="img-zoom">
                        <img className="teamimg-zoom" src={item.Staff_Image.url} alt={item.Staff_Image && item.Staff_Image.alternativeText ? item.Staff_Image.alternativeText : item.Name + ' - ' + item.Designation + AltSiteName} />
                        </div>
                      {/* </a> */}
                    {/* </Link> */}
                    <div className="team-block">
                      {/* <Link to={`${TeamBaseURL}/${item.URL}`} className="img-zoom"> */}
                        <div className="img-zoom">
                           <span className="date-text">{item.Name}</span>
                        </div>
                      {/* </Link> */}
                      <span className="profile">{item.Designation}</span>
                      <Link to={`${ContactFormURL}?tid=${item.id}`} className="mail">Send an email</Link>
                    </div>
                  </div>
                  {/* } */}
                </>
              })}
            </div>
            :
            <TeamListFilter teams={props.teams} Teamfilter={props.Teamfilter} />
          }
          <section className="loadmore-team">
            <Container>
              <Row>
                {postsToShow && postsToShow.length > 11 && postsToShow.length >= next && filter === "ALL" &&
                  <a onClick={handleShowMorePosts} class="btn">Load More</a>
                }
              </Row>
            </Container>
          </section>
        </Container>

      </section>

    </React.Fragment>
  )
}
export default Team
