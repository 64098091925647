import React, { useState } from "react";
import { Col, Row , Container} from 'react-bootstrap';
import CountUp from 'react-countup';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

const Stats = (props) => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <div className="home-stats">
                <Container>
                <Row>
                {props.stats.map((item, key) => {
                    const count = item.Value;
                    return <>
                    <Col xs="6" md="6" lg="3" className="stat-wrap">
                        <h2 ref={ref}>
                            <CountUp
                                start={inView ? COUNT_UP_START : count }
                                end={item.Value}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                            />{item.Value_Type}
                        </h2>
                        <div className="stats-caption">{item.Caption}</div>
                    </Col>
                    </>
                    })}                    
                </Row>
                </Container>
        </div>
    )
}

export default Stats


