import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './blurbContent.scss';
import parse from 'html-react-parser';
const BlurbContentList = (props) => {
    return(
        <div className="blurb-list-content-wrap">
            <Row>
                <div className="blurb-header">
                    {props?.heading && <h3 className="highlight-text">{props.heading}</h3>}
                    {props?.introtext && <p className="small-title">{props.introtext}</p>}
                </div>
            </Row>
            <Row>
                {props.content && props.content.map((item, index) => {
                    return (
                    <div className="list-item" key={index}>
                        <Row>
                            <Col xs={2} md={1} className="blurb-icon p-0">
                            {item?.Icon?.url &&
                                <img src={item.Icon.url} alt={item.Icon.alternativeText?item.Icon.alternativeText:item.Heading }/>
                            }
                            </Col>
                            <Col xs={10} md={11} className="blurb-content">
                                {item?.Heading && <h5>{item.Heading}</h5>}
                                {item?.Content && parse( item.Content) }
                            </Col>
                        </Row>
                    </div>
                    )
                  })
                
                }
            </Row>
        </div>
    );
};
export default BlurbContentList;