import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect";
import "./Vacancies.scss";
import { Careers } from "../../../queries/common_use_query";
import { CarerrBaseURL } from "../../utils";
const Vacancies = () => {
  const {loading:loading, error:error, data:data} = Careers();
  const List = [
    {
      Title: "Lettings Consultant",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },
    {
      Title: "Property Manager",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },

    {
      Title: "Sales Negotiator",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },
    {
      Title: "Property Manager",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },
    {
      Title: "Trainee Property Consultant",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },
    {
      Title: "Lettings Consultant",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam massa dolor,condimentum vel rutrum sit amet, ultriciesac mauris.",
    },
  ];

  let [reviewList, setCustomerReview] = useState([]);
  let intialNumberOfReview = 3;

  useEffect(() => {
    if (isMobileOnly) {
      intialNumberOfReview = 2;
    } else if (isTablet || isIPad13 || isIOS13) {
      intialNumberOfReview = 6;
    }
  }, []);

  useEffect(() => {
    setCustomerReview(List.slice(0, intialNumberOfReview));
  }, []);

  return (
    <React.Fragment>
      <section className="vacancies car-vacancy" id="current-vacancies">
        <Container>
          <Row>
            <Col>
              <h2>Vacancies at Balgores Car & Van Leasing</h2>
              
                <div className="vacancies-wrapper">
                  {data && data.careers.length > 0 && data.careers.map((item, index) => {
                    if(item.Car_And_Van_Leasing_Vacancy == true) {
                    return(
                    <div className="vacancies-info">
                      <h3> {item.Job_Opening}</h3>
                      <ul className="sm-info">
                        <li>{item.Type}  <span>•</span> </li>
                        <li>{item.Location}</li>
                      </ul>
                      <p> {item.Listing_Intro}</p>
                      <Link to={`${CarerrBaseURL}/${item.URL}`} className="more-info">
                        View details
                      </Link>
                    </div>                    
                    )
                    }
                    })}
                </div>
              
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Vacancies;
