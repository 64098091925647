import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Img01 from "../../../images/StaticModules/mortage.png"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import { AltSiteName } from "../../utils";

import './Advice.scss'

// markup
const Advice = (props) => {
    return (
        <React.Fragment>
            <div className="advice finance-wrap">
                {props.leftcontent &&
                    <div className="finance-block-btm">
                    <Container>
                        <Row>
                            <Col className="d-md-flex justify-content-between">
                                <div className="about">
                                    {props.leftcontent &&
                                        parse(props.leftcontent)
                                    }
                                </div>
                                <div className="info">
                                    {props.rightimage &&
                                        <div className="info-img d-lg-flex">
                                        <img src={props.rightimage.url} alt={props.rightimage.alternativeText?props.rightimage.alternativeText:"Who are Mortgage Advice Bureau?"+AltSiteName} />
                                        </div>
                                    }
                                    {props.rightcontent &&
                                        parse(props.rightcontent)
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    </div>
                    }
                    {props.greyblockcontent &&
                    <div className="insurance-wrapper">
                        <Container>
                            <Row >
                                <Col>
                                {props.greyblockcontent &&
                                    parse(props.greyblockcontent)
                                }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    }
                
            </div>
        </React.Fragment >
    )
}
export default Advice