import React, {useEffect} from 'react';
import { MapMarkerInfoBox } from './map/map-marker';
import LocRatingMap from './map/loc-rating-map-office';
import { OfficeBaseURL } from './utils';
import { useLocation } from "@reach/router"


const OfficeLandingMap = (props) => {   
    const location = useLocation();
    var rootpath = location.origin;
    // Get Map Details 
    let mapItems = [];
    {props.GQLOffices && props.GQLOffices.map((node, key) => {
        if(node.Latitude && node.Longitude) {
            let mapItem = {};
            mapItem['lat']  = node.Latitude;        
            mapItem['lng']  = node.Longitude;
            const mapMarkerImageShowFlag = 0;
            var link = "<a class='mapviewbtn' target='_parent' style='float:left;width:100%;color: #fff;font-weight:bold;margin:15px 0;background: #1B1434;padding: 8px;border-radius: 4px;text-align: center;' href="+rootpath+OfficeBaseURL+node.URL+">View Office</a>";
            var officetitle = "<span style='font-weight:bold;display: inline-block;margin-bottom: 5px;'>"+node.Name+"</span><br>";
            let mapItemHtml = MapMarkerInfoBox(officetitle + node.Office_Address + link,  mapMarkerImageShowFlag);
            mapItem['html'] = mapItemHtml;
            mapItems.push( mapItem );
        }
    })};
    return (
        <div className="office-landing-map">
            <LocRatingMap data={mapItems}/>
        </div>
    )
}

export default OfficeLandingMap
