import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import parse from 'html-react-parser';
import StaticRightBlock from '../PropertyDetails/RightBlock/StaticRightMember'
import BreadCrumbs from '../PropertyDetails/BackButton/BreadCrumbs'

import { getSrc } from "gatsby-plugin-image";
import { AltSiteName } from "../utils";
import "./StaticPage.scss"
import NewsletterFormModule from '../StaticModules/Book/Newsletter-Form-Module'
import BackParent from '../BackParent'
import AccordionList from '../StaticModules/Terms/AccordionList'
import MortgageCalculator from "../ValuationForm/ValuationInfo/MortgageCalculator";
const StaticPage = (props) => {
    return (
        <>
        <div className="static-page">
        <BreadCrumbs/>                    
            <Container>
                <Row className={!props.sidebar?"justify-content-around":""}>
                    <Col md={12} lg={props.title == "Report a Fault" ? 9 : 8} className="content-section-static">
                        {props.title?
                        <h1>{props.title}</h1>
                        :""}
                        {props.page === 'calculator' ?
                            <MortgageCalculator/>
                        :""}
                        {props.image ?
                            <div className="static-banner">
                                <img src={getSrc(props.image.url_sharp)} alt={props.image.alternativeText ? props.image.alternativeText : props.title + AltSiteName} />
                            </div>
                        : ""}
                        {props.highlightedcontent ?
                            <div className="highlight-text">
                                {parse(props.highlightedcontent)}    
                            </div>
                        :""}
                        {props.content ?
                        <div className="static-full-content">
                        {parse(props.content)}
                        </div>
                        :""}
                        {props.accordion &&
                            <AccordionList mainaccrodion = {props.accordion.length > 0 ? props.accordion:""} subaccordion={props.subaccordion.length > 0?props.subaccordion:""} />
                        }
                    </Col>
                    {props.sidebar?
                    <Col md={6} lg={4}>
                        <StaticRightBlock staff={props.staff} />
                    </Col>
                    :""}
                    
                </Row>
            </Container>
            {props.newslettermodule === "shownewslettermodule" ?
                <Container>
                    <Row>
                        <Col md={12} lg={8}>
                            <NewsletterFormModule color="blue-static" />
                        </Col>
                    </Row>
                </Container>
            : ""}
            
        </div>
        </>
    );
};
export default StaticPage;
