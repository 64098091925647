import React, { useState } from "react";
import { Link } from "gatsby"
import Img01 from "../../../images/Office/contact-img.jpg"
import ScrollAnimation from 'react-animate-on-scroll';
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import "./Connect.scss"
import ContactForm from "../../forms/contact";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { AltSiteName } from "../../utils";
const ContactModule = (props) => {
    const Departments = [
        { value: "Option", label: "Option" },
        { value: "Option1", label: "Option1" },
        { value: "Option2", label: "Option2" },
        { value: "All Offices", label: "All Offices" },
    ];
    var team = props.staff
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Staff_Image_Transforms) {
    processedImages = team.imagetransforms.Staff_Image_Transforms;
    }
    return (
        <React.Fragment>
            <section className="connect-us contact-module">
                <Container>
                    <Row>
                        <Col>
                            <div className="connect-wrapper">
                                <div className="contact-info">
                                    
                                        <h2>Contact Us</h2>
                                        <p>Whether you're looking to sell, buy, let or rent, we're here to help. All of our offices also have their own in-house Mortgage Adviser. Get in touch with us today by filling out this form and we'll get back to you as soon as possible.</p>                                        
                                    
                                </div>
                                <div className="form-wrapper">
                                    
                                        <ContactForm />
                                        {/* <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Your Name" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Your email" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="number" placeholder="Your phone number" />
                                            </Form.Group>

                                            <Form.Group controlId="exampleForm.ControlTextarea1" >
                                                <Form.Control placeholder="Write your message here..." as="textarea" rows={3} className="area" />
                                            </Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3 data">
                                                    <Form.Check
                                                        inline
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                    />
                                                    <span className="form-check-label">
                                                        I have read and agree to the <Link to="#"> Terms and
                                                            Conditions, </Link> <Link to="#"> Privacy Policy </Link> and <Link to="#"> Cookies Policy. </Link>
                                                    </span>
                                                </div>
                                            ))}
                                            <Button variant="primary" type="submit">
                                                Submit </Button>
                                        </Form> */}
                                    
                                </div>
                                {team &&
                                <div className={`contact-details sm-connect d-md-none ${props.style}`}>                                   
                                    <div className="contact-wrapper">
                                        <div className="contact-img">
                                        <ImageTransform imagesources={team.Staff_Image && team.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.sidebartile" attr={{ alt: team.Staff_Image.alternativeText?team.Staff_Image.alternativeText:team.Name+' - '+team.Designation+AltSiteName}} imagetransformresult={processedImages} id={team.id}/>                           
                                        </div>
                                        <div className="contact ">
                                        <h3>{team.Name}</h3>
                                        <span className="profile">{team.Designation}</span>
                                        <span>
                                            <Link to={`/contact?tid=${team.id}`} className="dark-text">Send email</Link> or <br />call
                                            <a href={`tel:${team.Phone_Number}`} className="tel">{team.Phone_Number}</a>
                                        </span>
                                        </div>
                                    </div> 
                                </div>
                                }
                            </div>
                        
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>

    )
}
export default ContactModule



