import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import AppLinks from "./app_links";

const DownloadApp = (props) => {    
    return (
        <React.Fragment>
            
            <div className="search-list-block blue-full-width">                
                <div className="appraisal-wrap">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="appraisal-bar">
                                <div className="left-block">
                                    <h2>Download the app</h2>
                                    {props?.brokerCode && 
                                        <p>
                                            Add the broker Code <strong>{props?.brokerCode}</strong>
                                        </p>
                                    }
                                </div>
                                <div className="right-block">
                                    <div className="app-links">
                                        {props?.brokerCodeImg && 
                                        <div className="broker-code-img"> <img src={props?.brokerCodeImg?.url} alt={props?.brokerCodeImg?.alternativeText ? props.brokerCodeImg.alternativeText:
                                            "Balgores Broker Code"} /></div>
                                        }
                                        <AppLinks androidlink={props?.androidlink} appstorelink={props?.appstorelink}/>
                                    </div>                           
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default DownloadApp;
