import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import FeaturesGrid from './FeaturesGrid';
import FeaturesCarousel from './FeaturesCarousel';
import './Features.scss';

// markup
const Features = (props) => {   
    return (
        <React.Fragment>            
            
            <section className= {`features feature-${props.displaytype && props.displaytype}`}>
            <Container>
                <Row>
                    <Col>
                      {props.title &&
                            <div className="features-title">
                                <h2>{props.title}</h2>
                            </div>}
                    </Col>
                    </Row>
                    <Row>
                             {(props.displaytype && props.displaytype === 'carousel') ? <FeaturesCarousel boxlist={props.boxlist} /> : <FeaturesGrid boxlist={props.boxlist} />}  
                    </Row>
            </Container>
                </section>
        </React.Fragment>                   
             
    )
}
export default Features