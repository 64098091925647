import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';
import GetCTA from "../../getcta";

const FeaturesGrid = (props) => {
    return (
    <React.Fragment>  
            <div className="box-wrapper">
                        {props.boxlist.map((item, index) => (
                            <div key={index} className="feature-box">
                                <Link to="#" className="icons">
                                    <i className="home"></i>
                                </Link>
                                <h3> {item.Box_Item_Title}</h3>
                                <p>{item.Box_Item_Caption}</p>
                                {item.Box_Item_Custom_Link?
                                    <Link className="btn btn-primary" to={item.Box_Item_Custom_Link}>
                                        {item.Box_Item_Link_Label}
                                    </Link>
                                    :
                                    <GetCTA class="btn btn-primary" Label={item.Box_Item_Link_Label} link={item.Box_Item_Link && item.Box_Item_Link.Primary_URL} />
                                }
                            </div>
                        ))}
            </div>               
    </React.Fragment>

  )
};
export default FeaturesGrid;