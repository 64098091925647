import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import parse from 'html-react-parser';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { getSrc } from "gatsby-plugin-image"
import GetCTA from "../../getcta";
import ScrollAnimation from 'react-animate-on-scroll';
import './Boost.scss'
// markup
const Boost = (props) => {
    return (
        <React.Fragment>
            <section className="boost boost-bottom">
                
                    <Container>
                        <Row>
                            <Col className="d-lg-flex justify-content-between align-items-start flex-row-reverse">
                                <div className="boost-img">
                                    
                                        <picture>
                                            <img src={getSrc(props.rightimage.url_sharp) } alt={props.rightimage.alternativeText?props.rightimage.alternativeText:""} />   
                                        </picture>
                                    
                                </div>
                                <div className="boost-wrapper">
                                    
                                        <div className="boost-info">
                                            {parse(props.leftcontent)}   
                                            
                                            <GetCTA class = "btn btn-primary d-md-block" Label = {props.linklabel}  link ={props.link && props.link.Primary_URL}/>                                    
                                        </div>
                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        </React.Fragment >
    )
}
export default Boost

