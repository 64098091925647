import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import $ from "jquery"

export default function FeefoReviews({
  language,
  culture,
  theme,
  stars,
  height,
  width,
  template,
  business,
  username,
}) {
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()

  useEffect(() => {
    $.get({
      url: "//api.feefo.com/api/javascript/mortgage-advice-bureau-limited",
      timeout: 5000, // 5 seconds
      dataType: "script"
    });
    
    if (typeof window !== "undefined" && window.feefoWidgetInstance) {
      window.feefoWidgetInstance.loadFromElement(ref.current, true)
      setLoaded(true)
    }
  }, [loaded])

  return (
    <div
      id="feefo-service-review-carousel-widgetId"
      className="feefo-review-carousel-widget-service"
      
    >
     
     
    </div>
  )
}

