import React, { useState, useEffect } from 'react';
const DateFormatAuction = (props) => {
	const d  = new Date(props.date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const we = new Intl.DateTimeFormat('en', { weekday: props.weekday?props.weekday:'long' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: props.month?props.month:'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
        <>
		{`${we} ${da} ${mo} ${ye}`}
        </>
	)
}
export default DateFormatAuction
