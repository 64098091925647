import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./Terms.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll"
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName, ContactFormURL } from "../../utils"
import SubAccordionList from "./SubAccordionList"
// Header component

const AccordionList = (props) => {
    // useEffect(() => {
    //     $('.subbody.card button').click(function(){
    //         $(this).toggleClass('subopen');
    //         $('.sub-accordion').slideToggle();
    //     });
    //     $('.main-cards.card button').click(function(){
    //         $('.sub-accordion').hide();
    //         $('.subbody.card button').removeClass('subopen');
    //     });
    // });
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-minus" : "icon-plus"
                    }
                ></i>
            </button>
        )
    }
    var mainaccordion = props.mainaccrodion && props.mainaccrodion[0].Accordion_List
    var subaccordion  = props.subaccordion && props.subaccordion[0].Sub_Accordion

    return (
        <React.Fragment>
            <section className="terms-section pt-0">


                                <div className="accordion-section">
                                    <Accordion flush>
                                    {mainaccordion && mainaccordion.map((mainitem,index) => {
                                        return<>
                                        {/* {mainitem.Accordion_Title !== "Some useful contacts" && */}
                                        <>
                                        <Card className="main-cards">                                        
                                            <div className="accordion-header card-header">
                                                <ContextAwareToggle eventKey={mainitem.id} className="accordion-header">
                                                    <h4>{mainitem.Accordion_Title}</h4>
                                                </ContextAwareToggle>
                                            </div>
                                            <Accordion.Collapse eventKey={mainitem.id}>
                                                <div className="accordion-card accordion-card-first card-body">
                                                    {mainitem.Accordion_Title === "Some useful contacts" ?
                                                    <SubAccordionList subaccordion={subaccordion} />
                                                    :
                                                    parse(mainitem.Accordion_Content)
                                                    }
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                        </>
                                        {/* } */}
                                        </>
                                        })}
                                        {/* {subaccordion &&
                                        <div className="subbody card"><div className="accordion-header card-header"><button type="button" className="inactive_card"><h4> Some useful contacts </h4><i className="icon-plus"></i>
                                        <i className="icon-minus"></i>
                                        </button></div></div>
                                        }
                                        {subaccordion &&
                                        <div className="sub-accordion">
                                        <>
                                        {subaccordion && subaccordion.map((subitem,index) => {
                                        return<>
                                        <Card className="balgores-heading">
                                            <div className="accordion-header card-header">
                                                <ContextAwareToggle eventKey={subitem.id} className="accordion-header">
                                                    <h4>{subitem.Sub_Accordion_Title}</h4></ContextAwareToggle>
                                            </div>
                                            <Accordion.Collapse eventKey={subitem.id}>
                                                <div className="accordion-card accordion-card-first card-body">
                                                    {subitem.Sub_Accordion_Team_Details && subitem.Sub_Accordion_Team_Details.map((subitemteam,index) => {
                                                    return<>
                                                    <h3>{subitemteam.Team_Office_Name}</h3>
                                                    <div className="staff-wrapper d-md-flex">
                                                    {subitemteam.Select_Office_Members.map((subteam,index) => {
                                                        return<>
                                                        <div className="staff-details d-flex">
                                                            <div className="staff-img">
                                                                <img src={getSrc(subteam.Staff_Image.url_sharp) } alt={subteam.Staff_Image.alternativeText?subteam.Staff_Image.alternativeText:subteam.Name+AltSiteName} />
                                                            </div>
                                                            <div className="staff-info d-flex flex-column">
                                                                <h3>{subteam.Name}</h3>
                                                                <span className="profile">{subteam.Designation}</span>
                                                                <Link to={`${ContactFormURL}?tid=${subteam.id}`} className="mail">Email</Link>
                                                            </div>
                                                        </div>
                                                        </>
                                                    })}
                                                    </div>
                                                    </>
                                                    })}
                                                </div>

                                            </Accordion.Collapse>
                                        </Card>
                                        </>
                                        })}
                                        </>
                                        </div>
                                        } */}
                                    </Accordion>
                                </div>
                            
                        
            </section>
        </React.Fragment>
    );
};
export default AccordionList;

