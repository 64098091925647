import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import PlayVideo from "../../../Components/Play/PlayVideo";
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './Sales.scss'
import parse from 'html-react-parser';
import { AltSiteName, AreaguideBaseURL, OfficeBaseURL } from "../../utils"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import getVideoId from "get-video-id"
// markup
const postsPerPage = 7;
let arrayForHoldingPosts = [];
const AreaguideListing = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [VideoID, setVideoID] = useState(false);
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(7);
    useEffect(() => {
        arrayForHoldingPosts = [];
    }, []);
    const loopWithSlice = (start, end) => {
        const slicedPosts = props.GQLAreaGuides.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
      };
    
      const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
      };
      useEffect(() => {
        props.GQLAreaGuides && loopWithSlice(0, postsPerPage);
      }, [props.GQLAreaGuides]);
    return (
        <React.Fragment>
            <div className="areaguide-listing">
            {postsToShow && postsToShow.map((item,index)=>{
                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.Tile_Image_Transforms) {
                processedImages = item.imagetransforms.Tile_Image_Transforms;
                }
                const videoid = item.Youtube_Video_URL && getVideoId(item.Youtube_Video_URL);
                var checkevenodd = index+1;
                const resultevenodd = (checkevenodd % 2  == 0) ? "even" : "odd";
                return<>
                <section className="sales">
                    <Container>
                        <Row>
                            <Col className="d-lg-flex justify-content-between">
                                <div className={`sales-img ${resultevenodd =="odd"?"order-2":"order-1"}`}>
                                    {/* <Link to={`${AreaguideBaseURL}/${item.URL}-area-guides`}> */}
                                    <Link to={`${OfficeBaseURL}${item.URL}/`}>
                                    <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="areaguides.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Name+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
                                    </Link>
                                    {/* </Link>    */}
                                    {item.Youtube_Video_URL && videoid ?
                                        <button
                                            type="button"
                                            className="btn-play"
                                            onClick={(e) => { setPlay(true); setVideoID(videoid.id);}}

                                        >
                                            <i className="icon-play"></i>
                                        </button>
                                    :""}
                                    
                                </div>
                                <div className={`sales-info ${resultevenodd =="odd"?"order-1":"order-2"}`}>
                                    <h2>{item.Name}</h2>
                                    {parse(item.Short_Intro)}
                                    {/* <Link to={`${AreaguideBaseURL}/${item.URL}-area-guides`} className="view-more">
                                        View details
                                    </Link> */}
                                    <Link to={`${OfficeBaseURL}${item.URL}/`} className="view-more">
                                    View branch
                                    </Link>
                                    
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
            </section>
                </>
            })}
            <section className="sales loadmore-guides">
            <Container>
            <Row>
            {postsToShow  && postsToShow.length >= next &&
                <a onClick={handleShowMorePosts} class="btn">Load More</a>
            }
            </Row>
            </Container>
            </section>

            </div>
            {VideoID &&
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={VideoID}
                isAutoPlay={1}
            />
            }
        </React.Fragment>
    )
}
export default AreaguideListing