import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './blurbContent.scss';
import { getSrc } from "gatsby-plugin-image"
import BlurbContentList from './blurbContentList';

const BlurbContent = (props) => {
    const moduleRightImage = props.image.url_sharp ? getSrc(props.image.url_sharp) : props?.image?.url;
    return(
        <section className="blurb-wrapper">
            <Container>
                <Row>
                    <Col lg={6}>
                        <BlurbContentList heading={props.heading} introtext={props.introtext} content={props.content} /> 
                    </Col>
                    <Col lg={6} className="text-center">                       
                        {moduleRightImage &&
                         <img src={moduleRightImage} alt={props.image.alternativeText?props.image.alternativeText:props.heading }/>
                        }
                    </Col>
                </Row>
            </Container>
        </section>        
    );
};
export default BlurbContent;