
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import AndroidIcon from "../../src/images/google-pay-icon.png"
import IosIcon from "../../src/images/app-store-icon.png"
import Slider from "react-slick";
import { AltSiteName } from "./utils";
import { getSrc } from "gatsby-plugin-image";

const settings = {
    dots: true,
    speed: 800,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 3020,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
// markup
const ModulesDetails = (props) => {
    return (
        <React.Fragment>
            <section className="available-on modules-details">
                <Container>
                    <Row>
                        <Col>
                           <h2>Available on</h2>
                           <div className="app-links">
                            <div className="app-wrap">
                           <a href={props.androidlink} target="_blank">
                            <img src={AndroidIcon} alt="Android Smart APP - Balgores" />
                           </a>
                           <a href={props.appstorelink} target="_blank">
                           <img src={IosIcon} alt="IOS Smart APP - Balgores" />
                           </a>
                           </div>
                           <div className="viewapp">
                           <a href={props.appstorelink} target="_blank">View on app store</a> or <a href={props.androidlink} target="_blank">play store</a>
                           </div>
                           </div>
                           
                        </Col>
                        <Slider className="banner smartapp-carousel" {...settings}>
                        {props.images && props.images.length > 0 && props.images.map((slide, index) => {
                            return<>                            
                            <div className="smart-app-slider">                                
                                <picture>                                
                                    <img src={getSrc(slide.Avilable_Grid_Image.url_sharp) } alt={slide.Avilable_Grid_Image.alternativeText?slide.Avilable_Grid_Image.alternativeText:"B-Smart APP Slider"+index+" "+AltSiteName} />
                                </picture>                                      
                            </div>                                                     
                        </>
                        })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default ModulesDetails