import React, { useState } from "react";
import { Link } from "gatsby"

import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import NewsletterPageForm from '../../forms/newsletter-page-form';
import "../../CarrierLanding/LatestNews/LatestNews.scss";

const NewsLetterFormModule = (props) => {

    return (
        <React.Fragment>
            
            <section className={`latest-news newsletter-module ${props.color?props.color:""}`}>
                
                    <Container>

                        <Row>
                            <Col>
                                <div className="news-info">
                                    <h2>Subscribe to our Newsletter</h2>
                                    <span className="sm-text">Stay up to date with latest news and featured properties</span>
                                        <NewsletterPageForm />
                                        {/* <div className="form-wrapper">

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Your Name" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Email Address" />
                                            </Form.Group>

                                        </div>

                                        <Form.Group className="mb-3 check" controlId="formBasicCheckbox" >
                                            <Form.Check type="checkbox" label="" />
                                            <span className="form-check-label">
                                                I agree to the <Link to="#">     T&C,
                                                </Link> <Link to="#"> Privacy Policy </Link> &  <Link to="#"> Cookie Policy. *</Link>
                                            </span>
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            subscribe
                                        </Button> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </section>
            
        </React.Fragment>

    )
}
export default NewsLetterFormModule
