
import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import GetCTA from "../../getcta";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useMediaQuery } from "../../../hooks/useMediaQuery";

const FeaturesCarousel = (props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const isTablet = useMediaQuery("(max-width: 991px)");
    return (
         <React.Fragment>              
                <CarouselProvider
                        naturalSlideWidth={100}        
                        naturalSlideHeight={(isMobile) ? 150 : 150}
                        totalSlides={props?.boxlist?.length}
                        infinite={true}
                        touchEnabled={true}
                        dragEnabled={true}
                        isPlaying={false}
                        visibleSlides={(isMobile) ? 1 : (isTablet) ?  2 : 4}
                        className="banner-imgslider feature-grid-slider d-block"
                >
                    <Slider className="banner-imgslider d-block feature-slider">
                        { props.boxlist.map((item, index) => {
                            return<>
                             <Slide key={index} index={index} className="feature-slider-item">
                                        <div className="feature-box">
                                            <Link to="#" className="icons">
                                                <i className="home">
                                                </i>
                                            </Link>
                                            <h3> {item.Box_Item_Title}
                                            </h3>
                                            <p>{item.Box_Item_Caption}</p>
                                            {item.Box_Item_Custom_Link?
                                            <Link className="btn btn-primary" to={item.Box_Item_Custom_Link}>
                                            {item.Box_Item_Link_Label}
                                            </Link>
                                            :
                                            <GetCTA class="btn btn-primary" Label={item.Box_Item_Link_Label} link={item.Box_Item_Link && item.Box_Item_Link.Primary_URL} />
                                            }
                                        </div>
                             </Slide>
                            </>
                        })}
                    </Slider>
                        <div className="slick-arrow slick-prev"><ButtonBack/></div>
                        <div className="slick-arrow slick-next"><ButtonNext/></div>
                    {isMobile &&
                        <DotGroup 
                        dotNumbers={false}
                        disableActiveDots={true}
                        showAsSelectedForCurrentSlideOnly={true}
                        />
                    } 
                </CarouselProvider>
        </React.Fragment>
)
};
export default FeaturesCarousel;