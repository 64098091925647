import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./Tetstimonials.scss";

import customerImg from "../../../images/customer.jpg";
import customerImg2 from "../../../images/customer2.jpg";
import feefo from "../../../images/feefo.svg";
import FeeFoSlider from "../../feefo";
import TrustPilotLogo from "../../trust-pilot-small-logo";
const Tetstimonials = (props) => {

  return (
    <React.Fragment>
      <section className="testimonial-review feefo-review-slide">
        <Container>
          <Row>
            

              <Col xl={12}>
                <div className="block-heading">
                  <h2>What our customers say</h2>
                  <p>Don't take our word for it - find out what our customers have to say about their experiences with Balgores.</p>
                </div>
              </Col>
            
          </Row>


          <div className="review-item">
            
              <Row>
                <FeeFoSlider/>
               
              </Row>
            
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tetstimonials;
