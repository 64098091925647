import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import Select from "react-dropdown-select";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Form, Button, Tabs, Tab } from "react-bootstrap"
import "./LandingBanner.scss"
import parse from 'html-react-parser';
import GetCTA from "../getcta";
import Trustpilot from '../../images/trustpilot.svg'
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../utils";
import $ from 'jquery';
import TeamFilter from "./TeamFilter";
import BreadCrumbs from '../PropertyDetails/BackButton/BreadCrumbs'
import { useMatch } from "@reach/router"
import TrustPilotBadge from "../trust-pilot-badge"
import { navigate } from "@reach/router";
import SearchInputBox from '../predictive-search/search-areas';
import AppStoreIcon from "../../../src/images/app-store-badge.png";
import GooglePlayIcon from "../../../src/images/google-play-badge.png";
import BrokerCodeImg from "../../../src/images/img-broker-code.png";
import ModalTiny from "react-bootstrap/Modal";
import ContactForm from "../forms/contact";

const Intro = (props) => {
    var areabreadcrumbs = ''
    areabreadcrumbs = useMatch("/branches/:item") 
    if(areabreadcrumbs!=null && areabreadcrumbs.item != 'area-guides') {
        areabreadcrumbs = areabreadcrumbs
    } else {
        areabreadcrumbs= ''
    }
    const [currentTab, setCurrentTab] = useState("buy")
    const [propertyval, setPropertyVal] = useState(null);
    const Brentwood = [
        { value: "Basildon", label: "Basildon" },
        { value: "Brentwood", label: "Brentwood" },
        { value: "Chelmsford", label: "Chelmsford" },
        { value: "Chelmsford", label: "Chelmsford" },
        { value: "Dagenham", label: "Dagenham" },
        { value: "Gravesend", label: "Gravesend" },
        { value: "Hornchurch Sales", label: "Hornchurch Sales" },
        { value: "Hornchurch Lettings", label: "Hornchurch Lettings" },
    ];
    const tabList = [
        { title: "Buy", key: "buy", slug: "/properties-for-sale/" },
        { title: "Rent", key: "rent", slug: "/properties-to-rent/" },
        { title: "New Homes", key: "new-homes", slug: "/new-homes-for-sale/" },
      ]
    const AllTypes = [
        { value: null, label: "All Properties" },
        { value: "house", label: "House" },
        { value: "apartment", label: "Apartment" },
        { value: "bungalow", label: "Bungalow" },
        { value: "maisonette", label: "Maisonette" },
        { value: "development-plot", label: "Development Plot" },
        { value: "land", label: "Land" },
      ];

    const Departments = [
        { value: "Option", label: "Option" },
        { value: "Option1", label: "Option1" },
        { value: "Option2", label: "Option2" },
        { value: "Option3", label: "Option3" },
    ];
    var introbanner = props.bannerimage && getSrc(props.bannerimage.url_sharp)
    var introtile   = props.image && getSrc(props.image.url_sharp)
    const [scroll, setScroll] = useState(false);

    const onSubmitSearch = () =>{
        let actionSlug = tabList.find((element) => element.key == currentTab).slug; 
        var searcValue = $(`.banner-content .tab-form-${currentTab} .searchVal`).val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase(); 
        if (searcValue !== "") {
          actionSlug = actionSlug + searcValue + "/"
        } else {
          actionSlug = actionSlug + "essex-and-kent/"
        }  
        if(propertyval){
          actionSlug = actionSlug + propertyval + "/"
        }
    
        navigate(actionSlug);
      }
    useEffect(() => {
        $('#buy-checkbox').attr('checked',true);

        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 1);
        }); 
      }, []);
      function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
          scrollTop: $(thishash).offset().top - 70
        }, 1000);
      }

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    useEffect(() => {
        if (props.pageclass == 'developer-landing-page') {
            setTimeout(function () {
                setSharefrndformOpen(true);
                $('.contact-enquiry-modal .contactFormClose').hide();
            }, 3000);
            if ( $(window).width() < 767 ) {
                $('.btn-intro').appendTo('.intro-tile-col');
            }
        }
    }, [])
      
    return (
        <React.Fragment>
            <div id={introbanner?"with-intro-full-banner":""} className={introtile?"with-banner-tile":""}>
            <section className={scroll?"landing-banner introbanner":"landing-banner introbanner scroll-intro"}>
                    {introbanner?
                    <div className="intro-banner-img">
                        <img src={introbanner} alt={props.bannerimage.alternativeText?props.bannerimage.alternativeText:props.title+AltSiteName}/>
                    </div>
                    :""}
                    <Container>
                        <Row>
                            <Col lg={introtile?"7":""}>
                                <div className="banner-content">
                                    <BreadCrumbs style="banner-breadcrumbs" alias={areabreadcrumbs?'area-guides':""} menulabel={areabreadcrumbs && areabreadcrumbs.item?areabreadcrumbs.item.replace("-area-guides", ""):""}/>
                                    <span className="sm-text">{props.title}</span>
                                    {parse(props.content)}
                                    {props.ctas && props.ctas.length > 0 && props.breadcrumbtitle!= "Careers" &&
                                    props.breadcrumbtitle !== "The Home Buying App" &&
                                    
                                    <div className="btn-intro">
                                    {props.ctas && props.ctas.length > 0 && !props.showBrokerCode && props.ctas.map((cta,index) => {
                                    var pattern = /^((http|https|ftp):\/\/)/;
                                    var target= ""
                                    if(pattern.test(cta.CTA_Custom_Link)) {
                                        target = "_blank";
                                    }   
                                    return<>
                                        {index === 0 && 
                                        <>
                                        {cta.CTA_Custom_Link && !target ?
                                            <>
                                            {cta.CTA_Custom_Link == '#contact-form-popup' ?
                                            <a href="javascript:void(0)" onClick={() => openSharefrndformModal()} className="btn btn-primary">{cta.CTA_Label}</a>
                                            :
                                            <Link to={cta.CTA_Custom_Link} className="btn btn-primary">{cta.CTA_Label}</Link>
                                            }
                                            </>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-primary" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link && !target ?
                                            <>
                                            {cta.CTA_Custom_Link == '#contact-form-popup' ?
                                            <a href="javascript:void(0)" onClick={() => openSharefrndformModal()} className="btn btn-transparent">{cta.CTA_Label}</a>
                                            :
                                            <Link to={cta.CTA_Custom_Link} className="btn btn-transparent">{cta.CTA_Label}</Link>
                                            }
                                            </>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-transparent" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link && target &&
                                            <a href={cta.CTA_Custom_Link} target={target} className="btn btn-primary">{cta.CTA_Label}</a>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link && target &&
                                            <a href={cta.CTA_Custom_Link} target={target} className="btn btn-transparent">{cta.CTA_Label}</a>
                                        }
                                        </>
                                        }
                                    </>
                                    })}
                                    </div>                                    
                                    }
                                    {/* ONLY For Home Buying App */}
                                    {props.ctas && props.ctas.length > 0 && props.showBrokerCode &&
                                         <div className="btn-intro image-btns">
                                            {props?.brokerCodeImg && 
                                                <div className="broker-code-img"> 
                                                    <img src={props?.brokerCodeImg?.url} alt={props?.brokerCodeImg?.alternativeText ? props.brokerCodeImg.alternativeText:
                                                    "Balgores Broker Code"} />
                                                </div>
                                            }
                                            {props.ctas && props.ctas.length > 0 && props.ctas.map((cta, index) =>{
                                                let btnClass = `btn ${(index%2 === 0) ? "btn-primary": "btn-transparent"}`;
                                                return(
                                                    <>
                                                    { cta.CTA_Custom_Link ? 
                                                       (cta.CTA_Label !== 'Google Play' && cta.CTA_Label !== 'App Store' ?
                                                            <Link to={cta.CTA_Custom_Link} className= {btnClass} key={index} >{cta.CTA_Label}</Link> :
                                                            <a href={cta.CTA_Custom_Link} className="gplay-icon" target="_blank" rel="noopener noreferrer" key={index}>
                                                               <img src={cta.CTA_Label === 'Google Play' ? GooglePlayIcon : AppStoreIcon } alt={cta.CTA_Label} />
                                                            </a> 
                                                        )                                               
                                                    :
                                                        <GetCTA Label={cta.CTA_Label} className= {btnClass} link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                                    }
                                                    </>
                                                )
                                            })

                                            }

                                         </div>

                                    }
                                    {/* ONLY For Home Buying App */}
                                    {/* ONLY CAREERS CTA */}
                                    {props.ctas && props.ctas.length > 0 && props.breadcrumbtitle === "Careers" &&
                                    
                                    <div className="btn-intro">
                                    {props.ctas && props.ctas.length > 0 && props.ctas.map((cta,index) => {
                                    return<>
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link &&
                                         <a href="javascript:;"  onClick={() => gotohash(cta.CTA_Custom_Link)} className="btn btn-primary">{cta.CTA_Label}</a>                                        
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link &&
                                        <a href="javascript:;"  onClick={() => gotohash(cta.CTA_Custom_Link)} className="btn btn-transparent">{cta.CTA_Label}</a>                                        
                                        }
                                        </>
                                    }
                                    </>
                                    })}
                                    </div>
                                                                        
                                    }
                                    {/* ONLY CAREERS CTA */}
                                    {props.showsearch ?
                                    <div className="banner">
                                     <Tabs
                                            defaultActiveKey={currentTab}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            onSelect={(val)=>{ setCurrentTab(val) }}
                                            
                                            >
                                            {tabList.map((tab, k) => {
                                                return (
                                                <Tab eventKey={tab.key} title={tab.title} key={k}>
                                                    <Form className={`search-banner tab-form-${tab.key}`} id={`search-banner-form`}>
                                                    <SearchInputBox />


                                                    <div className="custom-selectbox before-border "> 
                                                        <Form.Group controlId="buy-checkbox">

                                                        <select 
                                                            onChange={e => {setPropertyVal(e.target.value)}}
                                                            className={"form-select type-select"} 
                                                            name="type"
                                                        > 
                                                            {AllTypes.map((op, k) => {
                                                            return (
                                                                <option value={op.value}>{op.label}</option>
                                                            )
                                                            })}

                                                        </select>
                                                        </Form.Group>

                                                    </div>
                                                    <Button className="search_btn" onClick={onSubmitSearch} variant="primary" type="button">Find a property</Button>
                                                    </Form>
                                                </Tab> 
                                                ) 
                                            })}
                                            </Tabs>
                                    </div>                      
                                    :""}
                                    {/* {props.showreviews ?
                                    <div className="banner">
                                     <div className="star-rating">
                                     <TrustPilotBadge /> 
                                    </div>
                                    </div>
                                    :""} */}
                                    {props.filters?
                                    <div className="react-selector">
                                    <TeamFilter handleChange={props.handleChange} handleChangeDept={props.handleChangeDept}/>
                                    </div>:""    
                                    }
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    {introtile?
                            <Col lg={5} className="intro-tile-col">
                            <div className="intro-tile-img">
                            <img src={introtile} alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}/>
                            </div>
                            </Col>
                            :""}
            </section>
            </div>
            { props.pageclass == 'developer-landing-page' &&
                <ModalTiny backdrop="static" dialogClassName="valuation-info" contentClassName="valuation-box book" show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="getin-touch contact-enquiry-modal">
                    <i className="icon-close contactFormClose" onClick={() => closeSharefrndformModal()}></i>
                    <ModalTiny.Header className="contact-close-btn">
                        <ModalTiny.Title className="w-100">
                            <h3 className="text-center mb-0">Contact us</h3>
                            <p class="form-intro">For further information or to book an appointment, please fill out the form below.</p>
                        </ModalTiny.Title>
                    </ModalTiny.Header>

                    <ModalTiny.Body className="event-section">
                        <ContactForm to_email_id="sales@balgoresupminster.com" formtype="developer_contact" />
                    </ModalTiny.Body>

                </ModalTiny>
            }
        </React.Fragment>
    )
}
export default Intro
