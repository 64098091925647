import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import PlayVideo from "../../../Components/Play/PlayVideo";
import GoogleReview from "../../../images/google-reviews.png";
import "./CustomerReview.scss";
import { useStaticQuery, graphql } from "gatsby"
import { AltSiteName } from "../../utils";
import { getSrc } from "gatsby-plugin-image"
import getVideoId from 'get-video-id';
import TrustPilotLogo from '../../trust-pilot-small-logo';
// Header component

const News = () => {
  const [isPlay, setPlay] = useState(false);
  const [VideoID, setVideoID] = useState(false);
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Customer_Review_Heading
        Customer_Review_Module {
          Customer_Name
          Customer_Review
          Customer_Review_Video_URL
          Customer_Review_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 654
                  quality: 80
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
        }
      }
    }
  }
`)
var review_heading = data.glstrapi.globalConfig.Customer_Review_Heading; 
var review_module = data.glstrapi.globalConfig.Customer_Review_Module;

  return (
    <React.Fragment>
      
        <section className="customer-review">
          <Container>
            <Row>
              <Col md="12">
                <div className="block-heading">
                  <h2>{review_heading}</h2>

                  <div className="google-review">
                    {/* <TrustPilotLogo/> */}
                    {/* <img
                      src={GoogleReview}
                      alt="Google Review"
                      className="google-review-logo"
                    />
                    <div className="google-rating-number">
                      <span>Rated</span>
                      <strong>4.8/5</strong>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
            {review_module && review_module.map((review,index) => {
            const videoid = review.Customer_Review_Video_URL && getVideoId(review.Customer_Review_Video_URL);
            return<>
              <Col md="6" className="pr">
                <div className={`customer-block ${videoid}`}>
                  <picture className="picture-block img-zoom">                   
                    <img src={getSrc(review.Customer_Review_Image.url_sharp) } alt={review.Customer_Review_Image.alternativeText?review.Customer_Review_Image.alternativeText:review.Customer_Review+' - '+review.Customer_Name+AltSiteName} />
                    {review.Customer_Review_Video_URL ?
                    <button
                      type="button"
                      className="btn-play"
                   
                      onClick={(e) => { setPlay(true); setVideoID(videoid.id);}}
                    >
                      <i className="icon-play"></i>
                    </button>
                    :""}
                  </picture>
                  <h3>{review.Customer_Review}</h3>
                  <span className="customer-name">{review.Customer_Name}</span>
                </div>
              </Col>
              </>
              })}
              
            </Row>
          </Container>
        </section>
      
      {VideoID &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={VideoID}
        isAutoPlay={1}
      />
      }
    </React.Fragment>
  );
};
export default News;
